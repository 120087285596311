.container-popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.192);
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .popup{
        position: relative;
        background-color: $dark_color;
        border-radius: 8px;
        padding: 3em;
        display: flex;
        flex-direction: column;
        color: white;
        text-align: center;
        max-width: 80%;
        max-height: 90%;

        .close_popup{
            position: absolute;
            right: 0.5em;
            top: 0.5em;
        }

        .inside-popup{
            overflow: hidden;
            overflow-y: scroll;
            
            -ms-overflow-style: none; // IE 10+
            overflow: -moz-scrollbars-none; // Firefox
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            ::-webkit-scrollbar {
                   -webkit-appearance: none;
                   width: 7px;
            }
            ::-webkit-scrollbar-thumb {
                   border-radius: 4px;
                   background-color: rgba(0, 0, 0, 0.5);
                   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
        }

        h3{
            margin-bottom: 1em;
        }

        .container-btns{
            display: flex;
            justify-content: space-between;
            gap: 1em;
            margin-top: 1em;

            .confirm_popup{
                background:$power_color;
                color: white;
            }

            .alf_btn_text span{
                margin-left: 0;
            }
        }

    
    }
}


.container-popup{
    .container_url{
        width: 100%;
        background-color: $dark_color_soft;
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        margin-top: 2em;

    }

    .contain_url_text{
        padding: 0.5em 1em;
        width: 300px;
        overflow: hidden;
        word-wrap: break-word;
    }

    .container-icon-copy{
        color: white;
        background-color: $power_color;
        padding: 0.5em 1em;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}