.containerSelect {
       position: relative;
       label {
              display: flex;
              width: 100%;
              text-align: left;
              font-weight: bold;
       }

       .containerOptions {
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: stretch;

              .options {
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     margin: 10px;
                     max-width: 60px;
                     flex-grow: 1;
                     height: 100%;
                     cursor: pointer;
                     filter: grayscale(1);
                     opacity: 0.5;

                     img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                     }
              }

              .options.select {
                     filter: grayscale(0);
                     opacity: 1;
              }
       }
}

.containerSelect.dice_to_roll {
       .options {
              max-width: 40px;
              max-height: 40px;
       }
}

.containerSelect.opener {
       .containerOptions {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 80px;

              .options {
                     position: absolute;
                     z-index: 0;
                     width: 100%;
                     height: 60%;
                     //transition: all 0.2s ease-in;
              }

              .options.select {
                     z-index: 10;
                     background-color: $bg_color;
              }
       }
}

.containerSelect.opener.open {
       .options {
              position: relative;
       }
       .options.select {
              background-color: transparent;
       }
}
