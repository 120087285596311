.displayHero {
       width: 100%;
       position: relative;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       .containerEnteteHeros {
              width: 100%;
              position: relative;
              display: flex;
              flex-direction: column;

              .containImgHeros {
                     width: 130px;
                     height: 130px;
                     border-radius: 25%;
                     overflow: hidden;
                     margin-bottom: 1em;

                     img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                     }
              }

              .containerDiceforHeros {
                     position: absolute;
                     left: 112px;
                     width: 50px;
                     height: 50px;

                     img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                     }

                     span {
                            color: $power_color;
                            font-weight: bold;
                            position: absolute;
                            left: 35px;
                            bottom: -13px;
                     }
              }

              h1 {
                     text-align: left;
                     font-size: 30px;
                     line-height: 26px;
                     max-width: 50%;
              }

              h2 {
                     text-align: left;
                     font-size: 20px;
                     font-weight: 600;
                     opacity: 0.7;
              }
       }
}
