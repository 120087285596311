$bg_color: #f7f8e6;
$bg_color_darker: #e5e6d4;
$dark_color: #151431;
$dark_color_soft: #224e55;
$power_color: #f5185b;
$power_color_lighter: #e75984;
$linear_bg_radial: radial-gradient(
  circle,
  $dark_color 52%,
  $dark_color_soft 100%
);
$linear_bg: linear-gradient(37deg, $dark_color 56%, $dark_color_soft 100%);
$font : 'Poppins', sans-serif;

//Poppins FONT
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,900;1,400;1,700&display=swap');

$padding_vertical: 20px;
$padding_horizontal: 15px;
$padding: $padding_horizontal $padding_vertical;
$max_window_width: 500px;

$border_radius_btn: 35%;

//General
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font;
  position: relative;
  width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;

  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

#root,
.App {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.App {
  max-width: $max_window_width;
  color: $dark_color;
  padding-top: 50px;
  background-color: $bg_color;
  //background-image: url("../assets/bg/bg_parchemin.png");
}

#bg_top {
  width: 650px;
  position: absolute;
  top: -39px;
  left: -99px;
}

#Topform {
  width: 650px;
  position: absolute;
  top: -39px;
  left: -99px;

  g {
    fill: url(#MyGradient);
  }
}

a {
  text-decoration: none;
}

.alf_btn {
  width: 50px;
  height: 50px;
  background-color: $bg_color;
  border-radius: $border_radius_btn;
  color: $dark_color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;

  img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }

  span {
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:focus,
  &:active {
    filter: contrast(200%);
  }
}

.alf_btn.desactif{
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

.alf_btn_text {
  width: max-content !important;
  border-radius: 15px;
  padding: 5px 15px;

  span {
    margin-left: 5px;
    font-weight: 600;
  }
}

//gère la version ordinateur
@import './responsive';

//Animation
@import './Animate/tada';

//Logo
@import './Default/logo';

//Menu
@import './Default/menu';
@import './Default/selecter_dice';

//Menu Select heros
@import './Default/aside';

//Elements principaux de la page
@import './Pages/homepage';
@import './Pages/login';
@import './Pages/help';
@import './Pages/main';
@import './Pages/displayHeros';
@import './Pages/displayBlocs';
@import './Default/throwdicer';
@import './Default/popup';
@import './Pages/formmodifheros';

//Loader qui charge la page avant affichage
@import './Default/loader.scss';

//Elements
@import './Elements/select';
@import './Elements/input';
@import './Elements/breadcrumb';
