main {
       width: 100%;
       min-height: 100%;
       display: flex;
       flex-direction: column;
       position: relative;
       padding: $padding;
       padding-bottom: 6em;

       .containerBtns {
              position: fixed;
              z-index: 99;
              top: $padding_horizontal;
              right: $padding_vertical;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .alf_btn {
                     background-color: $bg_color_darker;
              }

              #open_menu {
                     position: relative;
                     margin-bottom: 1em;
                     box-shadow: 0 0 5px rgba(24, 24, 27, 0.288);
              }

              #modif_heros {
                     position: relative;
                     opacity: 0.7;
              }
       }
}
