@media screen and (min-width: 500px) {
       .App {
              max-width: none;
       }

       #Topform {
              right: -67px;
              left: auto;
              transform: scaleX(-1);
       }

       aside {
              transform: translateX(0) !important;
       }

       main {
              padding-left: 450px !important;
              padding-top: 5em !important;
       }

       #homepage {
              padding: 0 !important;
       }

       .containerThrowDicer {
              left: auto !important;
              right: 0px;
              align-items: flex-end;
              padding-right: 15px;
              background: linear-gradient(
                     to left,
                     #fff,
                     rgba(255, 255, 255, 0)
              ) !important;

              .containerExplain {
                     text-align: right !important;
              }

              .dicethrow {
                     margin-right: 15px;
              }
       }

       .containerThrowDicer.closed {
              transform: translateX(150%) !important;
       }

       main .containerBtns {
              right: auto !important;
              flex-direction: row !important;
              align-items: baseline !important;

              .alf_btn {
                     margin: 0 5px;
              }

              #open_menu {
                     display: none;
              }
       }

       #container_menu {
              right: 0px;
              left: initial !important;
       }

       .containerBlocs .displayerblocs_LIST .containerElementBloc .popupNote {
              width: 50% !important;
              left: initial !important;
              right: 15px !important;
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.329);
       }
}
