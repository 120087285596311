.containerBlocs {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .displayerblocs {
    width: 100%;
    order: 10;

    .enteteBloc {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      margin: 1em 0;
      margin-top: 3em;

      h4 {
        margin: 0 1em;
        text-align: center;
      }

      .fioriture:last-child {
        transform: scaleX(-1);
      }

      .copy {
        position: absolute;
        z-index: 1;
        top: -10px;
        width: 100%;
        opacity: 0.1;
        font-size: 1.1em;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 0.3em;
        text-align: center;
      }
    }

    .containerElementBloc {
      width: 100%;
      padding: 0 1em;
      background-color: $bg_color_darker;
      border-radius: 25px;

      display: flex;
      flex-direction: column;
    }
  }
}

.containerBlocs .displayerblocs_LIFE {
  order: 1;
}

.containerBlocs .displayerblocs_UNIT {
  order: 2;
}
.containerBlocs .displayerblocs_CARA {
  order: 3;
}
.containerBlocs .displayerblocs_COMP {
  order: 4;
}

/*** ********************
*********** BLOCS  *****
*********************/

.elementBloc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid grey;

  padding: 0.5em 0;

  .containerSelectedBtn {
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;

    .selectedBtn {
      width: 10px;
      height: 10px;
      background-color: $dark_color_soft;
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.363);
      border-radius: 150%;
    }

    .selectedBtn.actif {
      background-color: $power_color;
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.363), 0 0 5px $power_color;
    }
  }

  .titre {
    text-align: left;
    width: 100%;
  }

  .value {
    text-align: right;
    margin-right: 1em;
    font-weight: bold;
  }

  .dice {
    font-size: 0.8em;
    opacity: 0.7;
    margin-right: 8px;
  }

  .alf_btn {
    flex-shrink: 0;
    background-color: transparent;
    color: $dark_color;
    opacity: 0.4;
  }
}

.elementBloc:last-child {
  border-bottom: none;
}

/*************************************
/*************** LIFE *************
/*************************************/

.containerBlocs .displayerblocs_LIFE .containerElementBloc {
  background-color: transparent;
  padding: 0;
  margin: 2em 0;
}

.BlocLife {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: none;

  .fa-heart {
    color: $power_color;
    flex-shrink: 0;
  }

  .firstLine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 1.5em;
  }

  .currentLife {
    flex-shrink: 0;
    margin-right: 1em;
    font-weight: bold;
  }

  .maxLife {
    flex-shrink: 0;
    margin-left: 1em;
  }

  .current_life_bar {
    -webkit-appearance: none;
    outline: none;
    color: $power_color;
    background: $power_color;
    border-radius: 15px;
    //box-shadow: 0 0 10px $main_color;
    cursor: pointer;
    height: 10px;
    width: 100%;
    max-width: none;

    &::-moz-range-thumb,
    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* également nécessaire sur le curseur */
      width: 1.1em;
      height: 1.1em; /* s'adapte à la hauteur de l'input */
      border-radius: 100%; /* pris en compte sur Webkit et Edge */
      background: $power_color; /* pris en compte sur Webkit only */
      border: 8px solid $bg_color;
      box-shadow: 0 0 10px $bg_color;
    }

    &:after {
      content: attr('max');
      color: white;
    }
  }
}

/*************************************
/*************** UNIT *************
/*************************************/

.containerBlocs .displayerblocs_UNIT .containerElementBloc {
  background-color: transparent;
  padding: 0;
  margin: 1em 0;
}

.containerBlocs .displayerblocs_UNIT .containerElementBloc ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.slider {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 21%;
  width: 100px;
  background-color: $bg_color_darker;
  border-radius: 20px;
  padding: 5px;
  margin: 0.5em;
  flex-grow: 4;
  user-select: none;
  position: relative;
  overflow: hidden;

  transition: all 0.2s linear;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    background: rgba(0, 0, 0, 0.4);
  }

  .bg_uniter {
    width: 110%;
    height: 110%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background-color: white;
  }

  .titre {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid grey;
    word-break: break-word;
    user-select: none;
    z-index: 2;
  }

  .containervalue {
    height: 70px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: 2;
  }

  .value {
    font-weight: bold;
    text-align: center;
    font-size: 2.2em;
    user-select: none;
    z-index: 2;
  }

  .max {
    opacity: 0.7;
    font-weight: 300;
    user-select: none;
    position: absolute;
    bottom: 17px;
    right: 15px;
    text-align: right;
    font-weight: 600;
    z-index: 2;
  }
}

.slider.actif {
  box-shadow: 0 0 5px white;
  background-color: white;
  color: $dark_color;

  .value {
    font-size: 4.5em;
  }
}

/*************************************
/*************** LIST *************
/*************************************/

.containerBlocs .displayerblocs_LIST {
  h4 {
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .alf_btn {
    margin-top: 15px;
    float: right;
    background-color: $bg_color_darker;
  }

  .containerElementBloc {
    background-color: transparent;
    padding: 0;

    .elementInNote {
      width: 100%;
      list-style: none;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;

      .texts {
        width: 100%;
        display: flex;
        flex-direction: column;

        .titre {
          font-weight: 500;
        }

        .shortdesc {
          opacity: 0.5;
          font-size: 0.7em;
        }
      }
    }

    .popupNote {
      position: fixed;
      background: $bg_color_darker;
      width: 100%;
      z-index: 99;
      bottom: 0;
      left: 0;
      right: 0;
      min-height: 82%;
      border-radius: 25px 25px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: $padding;
      overflow: hidden;

      animation: appearPopupNote 0.3s 1;
      transition: all 0.2s ease-in;

      .alf_btn {
        align-self: flex-end;
        z-index: 2;
        background-color: transparent;
      }

      #BtnaddItem {
        background-color: $power_color;
        color: white;
        align-self: center;
      }

      .titre {
        font-weight: bold;
        font-size: 1.1em;
        margin-bottom: 1em;
        z-index: 2;
      }

      .shortdesc,
      .desclong {
        opacity: 0.8;
        text-align: left;
        font-size: 0.9em;
        padding: 1em 0;
        width: 90%;
        z-index: 2;
      }

      .shortdesc {
        border-bottom: 1px solid $dark_color;
      }

      .desclong {
        max-height: 75vh;
        overflow-y: scroll;

        -ms-overflow-style: none; // IE 10+
        overflow: -moz-scrollbars-none; // Firefox
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      textarea,
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid black;
        z-index: 2;
      }

      textarea {
        height: 500px;
        max-width: 100%;
      }

      .bgImg {
        position: absolute;
        right: -22%;
        top: -15%;
        width: 80%;
        opacity: 0.5;
        z-index: 1;
      }
    }

    .popupNote.close {
      transform: translateY(150%);
    }
  }
}

@keyframes appearPopupNote {
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0%);
  }
}
