.selecterDice {
       display: flex;
       flex-direction: column;
       position: absolute;
       right: 0;
       bottom: 80px;
       z-index: 98;
       user-select: none;

       .containerDice {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
       }

       .dice {
              position: relative;
              z-index: 98;
              margin: 5px;
              transition: all 0.2s ease-in;
              background-color: transparent;
              user-select: none;
       }

       .nbreselected {
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 99;
              border-radius: 100%;
              background-color: $power_color;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              transition: all 0.2s ease-in;
       }

       .typeDice {
              position: absolute;
              bottom: 0;
              right: 6px;
              z-index: 99;
              font-weight: bold;
              color: $dark_color;
              text-shadow: 0 0 10px white;
       }

       #dice_2 {
              display: none;
       }

       #dice_6 {
              .alf_btn {
                     width: 40px;
                     height: 40px;
              }
       }
}
