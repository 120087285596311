.containerThrowDicer {
       height: 100%;
       width: 82%;
       position: fixed;
       z-index: 99;
       top: 0px;
       left: 0px;
       padding-left: 15px;
       padding-top: 25px;
       display: flex;
       flex-direction: column;
       overflow: scroll;
       background: linear-gradient(0.25turn, #fff, rgba(255, 255, 255, 0));
       -ms-overflow-style: none; // IE 10+
       overflow: -moz-scrollbars-none; // Firefox
       -ms-overflow-style: none; /* IE and Edge */
       scrollbar-width: none; /* Firefox */

       transition: all 0.3s ease-in-out;
       ::-webkit-scrollbar {
              display: none;
       }

       .containerExplain {
              max-width: $max_window_width;
              background-color: #224e55ab;
              padding: 5px;
              border-radius: 5px;
              color: white;
              position: relative;
              margin: 2px 0;
              text-align: left;
              width: 90%;

              transition: all 0.3s ease-in-out;
              animation: appear 2s 1;
       }

       .separator {
              height: 15px;
       }

       .dicethrow {
              width: max-content;
              height: 50px;
              position: relative;
              margin-bottom: 10px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              border-radius: 15px;

              //background-color: $power_color;
              //box-shadow: 0 0 5px $dark_color;

              transition: all 0.3s ease-in-out;
              //animation: appear 2s 1;

              img {
                     width: 50px;
                     height: 50px;
                     object-fit: contain;
                     position: relative;
                     z-index: 0;
                     transform: rotate(10deg);
                     position: absolute;
                     left: 11px;
                     top: -7px;
              }

              span {
                     z-index: 1;
                     color: white;
                     text-shadow: 0 0 15px $power_color;
                     font-size: 2em;
                     font-weight: bold;
              }
       }
}

.containerThrowDicer.closed {
       transform: translateX(-150%);
}

@keyframes appear {
       from {
              opacity: 0;
       }
       to {
              opacity: 1;
       }
}
