#help{
    padding: 0 !important;

    section{
        padding: 5em 0;
        padding-top: 10em;

        .inside{
            flex-direction: column;

            h1{
                margin-bottom: 3em;
                text-align: center;
            }

            h2{
                margin-bottom: 1em;
                margin-top: 1em;
            }

            h3{
                margin-top: 1em;
                margin-left: 1em;
                opacity: 0.7;
            }

            blockquote{
                width: 100%;
                color: white;
                background-color: $dark_color;
                border-radius: 8px;
                padding: 2em;
                margin: 1em 0;
            }
        }
    }

    
}