.labelForInput {
       width: 100%;
       display: flex;
       flex-direction: column;
       position: relative;
       margin: 5px 0;
       background-color: $bg_color_darker;
       padding: 15px 25px;
       border-radius: 15px;

       span {
              font-size: 0.7em;
              opacity: 0.6;
              padding: 0;
              margin: 0;
              transition: all 0.2s ease-out;
              text-align: left;
              margin-bottom: 5px;
              width: 100%;
       }

       textarea,
       input {
              border: none;
              padding: 0;
              margin: 0;
              text-align: left;
              background-color: transparent;
              font-family: "Poppins";
              position: relative;
              width: 100%;

              &:focus {
                     border: none;
                     outline: none;
              }
              &:focus-visible {
                     border: none;
                     outline: none;
              }
       }

       svg {
              position: absolute;
              right: 45px;
              bottom: 32px;
              opacity: 0.6;
       }
}
