aside {
       width: 75%;
       max-width: 400px;
       height: 100%;
       position: fixed;
       z-index: 99;
       bottom: 0;
       left: 0;
       border-radius: 0 35px 35px 0;
       background: $linear_bg;

       font-size: 1em;
       font-weight: lighter;

       color: white;

       box-shadow: 0 0 10px rgba(0, 0, 0, 0.507);

       display: flex;
       flex-direction: column;
       align-items: flex-start;
       text-align: left;

       padding: 10vh 2em 3em 2em;

       transition: transform 0.4s ease-out;

       transform: translateX(-150%);

       .scroller {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              overflow-y: auto;
              -ms-overflow-style: none; // IE 10+
              overflow: -moz-scrollbars-none; // Firefox
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
              height: 100%;

              ::-webkit-scrollbar {
                     -webkit-appearance: none;
                     width: 7px;
              }
              ::-webkit-scrollbar-thumb {
                     border-radius: 4px;
                     background-color: rgba(0, 0, 0, 0.5);
                     -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
              }
       }

       h4 {
             font-weight: 600;
             margin-bottom: 1em;

       }

       .listAside {
              margin-bottom: 1em;
              padding-left: 0.7em;
              display: flex;
              flex-direction: column;

              ul {
                     display: flex;
                     flex-direction: column;

                     .LIFE {
                            order: 1;
                     }

                     .UNIT {
                            order: 2;
                     }
                     .CARA {
                            order: 3;
                     }
                     .COMP {
                            order: 4;
                     }
                     .LIST {
                            order: 5;
                     }
              }

              li {
                     list-style: none;
                     opacity: 0.6;
                     cursor: pointer;
                     margin-bottom: 0.5em;

                     &:hover {
                            opacity: 1;
                     }

                     a {
                            color: white;
                            text-decoration: none;
                     }
              }

              .alf_btn {
                     color: white;
                     background-color: $power_color;
                     margin: 5px;
              }
       }

       .ListHeros {
              flex-direction: column;
              flex-wrap: wrap;

              .containImage{
                     display: flex;
                     border-radius: 100%;
                     overflow: hidden;
                     width: 20px;
                     height: 20px;
                     margin-right: 1em;
                     filter: grayscale(1);
              }

              

              .heroSelecter {
                     display: flex;
                     flex-direction: row;
                     align-items: center;
                     cursor: pointer;

                     transition: all 0.2s linear;
                     

                     img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                     }

                     &:hover {
                            filter: grayscale(0);
                     }

                     
              }

              .heroSelecter.selected .containImage {
                     filter: grayscale(0);
                     width: 25px;
                     height: 25px;
                     border: 2px solid $power_color;
              }

              .heroSelecter.selected{
                     opacity: 1;
              }
       }

       #btnLogOut {
              margin-top: 1em;

              .alf_btn {
                     background-color: $power_color;
                     color: white;
              }
       }
}

aside.opened {
       transform: translateX(0);
}
