.loginpage {
       width: 100%;
       height: 100vh;
       display: flex;
       justify-content: center;
       align-items: center;
       background-color: $bg_color;

       .ContainerFormConnect {
              border-radius: 15px;
              background: $linear_bg;
              color: white;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 4em;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.349);
              width: 800px;
              max-width: 100%;
              z-index: 1;

              form {
                     width: 100%;
              }

              .enTete {
                     width: 100%;
                     position: relative;
                     text-align: center;
                     padding-bottom: 35px;
              }

              .labelForInput {
                     background: transparent;
                     color: white;

                     span {
                            font-size: 1em;
                     }

                     input {
                            padding: 15px;
                            color: white;
                            box-shadow: inset 0 0 10px $dark_color;
                            background: rgba(255, 255, 255, 0.05);
                            border-radius: 15px;

                            &:focus {
                                   background: rgba(255, 255, 255, 0.15);
                            }
                     }
              }

              .alf_btn {
                     background-color: $power_color;
                     color: white;
                     width: 100% !important;
              }

              .message_error {
                     min-height: 1em;
                     margin: 15px 0;
                     font-size: 0.8em;
                     width: 100%;
                     text-align: center;
              }

              .link_inscription {
                     font-size: 0.8em;
                     opacity: 0.7;
                     margin-top: 1em;
                     text-decoration: underline;
                     cursor: pointer;

                     &:hover {
                            opacity: 1;
                     }
              }
       }

       .containerElementFloat {
              position: fixed;
              z-index: 50;

              img {
                     width: 100%;
                     object-fit: contain;
              }
       }

       #dice1 {
              top: 14%;
              right: 19%;
              transform: rotate(-11deg);
       }

       #dice2 {
              top: 50%;
              left: 12%;
              transform: rotate(24deg);
              width: 200px;
       }
}

@media screen and (max-width: 1300px) {
       .loginpage {
              #dice2 {
                     left: -5%;
              }
       }
}

@media screen and (max-width: 700px) {
       .loginpage {
              .ContainerFormConnect {
                     padding: 2em;
              }

              #dice1 {
                     right: -45px;
              }

              #dice2 {
                     top: 70%;
                     left: -24%;
              }
       }
}
