#container_menu {
       width: 100%;
       max-width: $max_window_width;
       position: fixed;
       bottom: 0;
       left: 0;
       z-index: 90;
}
#menu {
       position: absolute;
       z-index: 99;
       bottom: $padding_horizontal;
       right: $padding_horizontal;
       border-radius: 15px;
       width: max-content;
       display: flex;
       flex-direction: row;
       align-items: center;

       > div {
              margin: 0 5px;
       }

       #throw_dice {
              background-color: $power_color;
              color: white;
              font-size: 1.5em;
              box-shadow: 0 0 10px $power_color;
              position: relative;
              z-index: 99;
              transition: all 0.2s ease-in;
       }

       #throw_dice.ready {
              transform: scale(1.2);
              box-shadow: 0 0 15px white;
              animation: tada 3s linear infinite alternate;
       }

       .containerInModifier {
              display: flex;
              flex-direction: row;
              align-items: center;

              .alf_btn {
                     background-color: $power_color_lighter;
                     color: white;
                     margin: 0 5px;
                     width: 40px;
                     height: 40px;
              }
       }
}
