.loader {
       position: fixed;
       z-index: 100;
       top: 0;
       width: 100%;
       height: 100vh;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       background-color: $dark_color;
       color: black;
       user-select: none;
       pointer-events: none;
       cursor: not-allowed;
}

.loader.simple {
       background: none;
       img {
              opacity: 0.3;
              width: 60px;
       }
}
