.Logo {
       font-weight: bolder;
       text-transform: uppercase;
       font-size: 1.3em;
       letter-spacing: 0.4em;
       margin-bottom: 1em;
       width: 100px;

       img {
              height: 100%;
              width: 100%;
              object-fit: contain;
       }
}
