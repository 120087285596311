.page-site {
       width: 100%;
       padding: 0;
       margin: 0;

       .inside {
              max-width: 1150px;
              width: 100%;
       }

       nav {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              width: 100%;
              z-index: 50;

              padding: 10px;

              .inside {
                     margin: auto;
                     display: flex;
                     flex-direction: row;
                     justify-content: space-between;
                     align-items: center;

                     .Logo {
                            height: 80px;
                     }

                     .alf_btn {
                            background-color: $power_color;
                            color: white;
                     }
              }
       }

       section {
              position: relative;
              min-height: 75vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .inside {
                     width: 100%;
                     padding: 0 1em;
                     display: flex;
                     flex-direction: row;
                     justify-content: space-between;

                     > div {
                            width: 50%;
                            flex-grow: 1;
                     }

                     .containerImg {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            max-width: 400px;

                            .containImg {
                                   position: relative;
                                   width: 100%;
                                   height: 400px;
                                   overflow: hidden;

                                   img {
                                          width: 100%;
                                          height: 100%;
                                          object-fit: contain;
                                   }
                            }
                     }

                     .containText {
                            max-width: 400px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            color: $dark_color;

                            h2 {
                                   font-size: 48px;
                                   line-height: 50px;
                                   margin-bottom: 0.7em;
                            }

                            .Text {
                                   opacity: 0.9;
                            }
                     }
              }
       }

       .heros {
              background-image: url("../../assets/bg/bg_home.jpg");
              background-position: center center;
              background-size: cover;
              background-repeat: no-repeat;

              .bg_black {
                     width: 100%;
                     height: 100%;
                     position: absolute;
                     background: $linear_bg;
                     opacity: 0.4;
                     z-index: 1;
              }

              .inside {
                     color: white;
                     text-align: right;
                     z-index: 2;
                     justify-content: flex-end;

                     h1 {
                            font-size: 55px;
                            line-height: 55px;
                            max-width: 400px;
                     }

                     .containText {
                            //width: 50%;
                            max-width: 57%;
                            align-items: flex-end;
                            color: white;

                            .alf_btn {
                                   margin-top: 2em;
                            }
                     }

                     .Text {
                            font-size: 20px;
                            font-weight: 400;
                            opacity: 0.8;
                     }
              }
       }

       .section2 {
              text-align: right;
              background-color: #224e558c;
              .inside {
                     .containText {
                            color: white;
                     }
              }
       }

       .section4 {
              min-height: auto;
              padding-bottom: 5em;

              .inside {
                     margin: auto;
                     text-align: center;
                     flex-direction: column;

                     .alf_btn {
                            margin: auto;
                            margin-top: 2em;
                            background-color: $power_color;
                            color: white;
                     }
              }
       }

       footer {
              background-color: $power_color;
              color: white;
              padding: 5em 0;

              .inside {
                     margin: auto;
                     display: flex;
                     flex-direction: row;
                     padding: 0 2em;

                     .col {
                            flex-grow: 1;
                            width: 100%;
                            text-align: left;
                            display: flex;
                            flex-direction: column;

                            h3 {
                                   margin-bottom: 15px;
                            }

                            a {
                                   text-decoration: none;
                                   color: white;
                                   margin: 5px 0;
                            }
                     }
              }
       }
}

@media screen and (max-width: 1140px) {
       .page-site {
              section {
                     .inside {
                            padding: 0 2em;
                     }
              }
       }
}

@media screen and (max-width: 500px) {
       .page-site {
              section {
                     .inside {
                            flex-direction: column;
                            margin: 5em 0;

                            > div {
                                   width: 100%;
                                   text-align: center;
                            }

                            .containText {
                                   margin-bottom: 2em;
                                   order: 1;

                                   h2 {
                                          font-size: clamp(20px, 4vw, 40px);
                                          line-height: clamp(20px, 4vw, 40px);
                                   }
                            }

                            .containerImg {
                                   order: 2;
                            }
                     }
              }

              .heros {
                     .inside {
                            margin-top: 10em;

                            h1 {
                                   max-width: none;
                            }

                            .containText {
                                   width: 100%;
                                   max-width: none;

                                   a {
                                          margin: auto;
                                          margin-top: 2em;
                                   }
                            }
                     }
              }

              footer {
                     .inside {
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .col {
                                   width: 40%;
                                   margin: 2em 1em;
                            }
                     }
              }
       }
}
