#form_modif_heros {
  display: flex;
  flex-direction: column;

  h1 {
    max-width: 55%;
    text-align: left;
    line-height: 1em;
    margin-bottom: 2em;
  }

  #btnDelHeros {
    color: red;
    opacity: 0.5;
    text-align: right;
    font-size: 0.7em;
    cursor: pointer;
  }
}

.Bloc {
  order: 50;
  margin: 10px 0;
  margin-top: 2em;
  padding: 2em 1em;
  border-radius: 15px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.356);

  .btnDeleteBloc {
    font-size: 0.7em;
    color: red;
    opacity: 0.4;
    margin-bottom: 15px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  /** LIFE */
  .containerInpLife {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .alf_btn {
      color: $power_color;
      font-size: 2em;
    }

    .labelForInput {
      max-width: 35%;
      background: transparent;
      padding: 0;

      input,
      span {
        text-align: center;
      }

      input {
        font-weight: bolder;
        font-size: 2em;
        border-bottom: 1px solid $dark_color;
      }
    }
  }

  /** UNIT */
  .containerInpUnit {
    display: flex;
    flex-direction: column;

    .containerModifUnit {
      display: flex;
      flex-direction: row;
      margin: 7px 0;
      align-items: flex-end;
      padding: 15px 5px;

      .labelForInput {
        width: auto;
        flex-grow: 1;
        background-color: transparent;
        padding: 0;
        width: 30%;
        margin: 0 5px;

        input {
          border-bottom: 1px solid $dark_color;
        }
      }

      .labelForInput[for='titre'] {
        width: 100%;
      }

      .alf_btn {
        opacity: 0.4;
        padding: 0;
        font-size: 0.8em;
      }
    }
  }

  /** CARA / COMP */
  .containerInpDefault {
    display: flex;
    flex-direction: column;

    .containerModifUnit {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 7px 0;
      align-items: flex-end;
      background-color: $bg_color_darker;
      padding: 15px;

      .labelForInput {
        width: auto;
        flex-grow: 1;
        background-color: transparent;
        padding: 0;
        width: 30%;
        margin: 5px 5px;

        input {
          border-bottom: 1px solid $dark_color;
        }
      }

      .labelForInput[for='titre'] {
        width: 100%;
      }

      .alf_btn {
        opacity: 0.4;
        padding: 0;
      }
    }
  }

  /* LIST */
  .containerInpList {
    display: flex;
    flex-direction: column;

    .containerModifUnit {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 7px 0;
      align-items: flex-end;
      background-color: $bg_color_darker;
      padding: 15px;

      .labelForInput {
        width: auto;
        flex-grow: 1;
        background-color: transparent;
        padding: 0;
        width: 30%;
        margin: 5px 5px;

        input {
          border-bottom: 1px solid $dark_color;
        }
      }

      .labelForInput[for='titre'] {
        width: 100%;
      }

      .labelForInput[for='short_desc'],
      .labelForInput[for='value'] {
        width: 100%;

        textarea {
          font-size: 0.8em;
        }
      }

      .alf_btn {
        opacity: 0.4;
        padding: 0;
      }
    }
  }

  .unit_in_bloc {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $bg_color_darker;
    border-radius: 15px;
    padding: 15px 25px;
    margin: 5px 0;

    position: relative;

    .labelForInput {
      flex-grow: 1;
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 40%;
      order: 1;

      input {
        padding: 0;
        margin: 0;
        min-width: none;
      }
    }

    .alf_btn {
      background-color: transparent;
      color: $dark_color;
      opacity: 0.3;
      flex-grow: 1;
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        opacity: 1;
      }
    }

    .labelForInput[for='titre'] {
      width: 100%;
      order: 0;
      input {
        text-align: center;
        font-weight: bold;
        font-size: 1em;
      }
    }

    .labelForInput[for='value'] {
      width: 100%;

      input {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
      }
    }

    .labelForInput[for='dice'] {
      width: 100%;

      input {
        text-align: center;
        opacity: 0.8;
      }
    }

    .labelForInput[for='current'] {
      width: 100%;
      order: 0;

      input {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
      }
    }
  }

  .unit_in_bloc[data-type='UNIT'] {
    .labelForInput[for='value'] {
      width: 100%;

      input {
        text-align: center;
        font-size: 1em;
      }
    }
  }
}

.Bloc[data-type='LIFE'] {
  order: 0;
}

.Bloc[data-type='UNIT'] {
  order: 1;
}

/** FORM CREATION PERSO */
#form_modif_heros.form_create {
  width: 100%;
  background: $linear_bg;
  color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  #closeBtn {
    position: fixed;
    top: $padding_vertical;
    right: $padding_horizontal;
    z-index: 106;
    color: white;
    background-color: transparent;
  }

  .containNav {
    width: 100%;
    max-width: 700px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $padding;

    .alf_btn {
      background-color: $power_color;
      color: white;
      width: 100%;
      margin-bottom: 15px;
    }

  
  }

  .scrollerForm {
    width: 100%;
    max-width: 700px;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: $padding;
  }
  .page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-shrink: 0;
    padding-bottom: 120px;
    overflow: hidden;

    .containResume {
      text-align: center;
      h2 {
        margin-bottom: 1em;
      }
    }

    .label {
      margin-bottom: 1em;
    }

    .labelForInput {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.404);
    }

    .containerOptions {
      flex-direction: column;

      .options {
        width: 100%;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg,
        img {
          width: 60px;
          margin-right: 1em;
        }
      }

      .options.select {
        text-decoration: underline;
      }
    }

    input {
      color: white;
    }

    select{
      width: 100%;
      padding: 1em;
      font-size: 1em;
      font-family: $font;
    }

    .containInps {
      max-height: 70vh;
      overflow: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; // IE 10+
          overflow: -moz-scrollbars-none; // Firefox
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }
          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
          }

      .containerSelect.Template {
        .containerOptions {
          max-height: 250px;
        }
      }

      .containerSelect.dice_to_roll {
        margin-top: 25px;
        .containerOptions {
          flex-direction: row;
          margin: 15px 0;

          .options {
            width: 60px;
          }
        }
      }

      .Bloc {
        padding: 0;
        margin: 0;
        height: 100%;
        overflow: hidden;
        text-align: center;
        box-shadow: none;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;

        -ms-overflow-style: none; // IE 10+
        overflow: -moz-scrollbars-none; // Firefox
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
        }

        h3 {
          display: none;
        }

        .btnDeleteBloc {
          display: none;
        }

        .containerInpsBtnAdd {
          height: 100%;
          padding-bottom: 85px;
          display: flex;
          flex-direction: column;

          .alf_btn {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        .containerInpLife {
          .labelForInput[for='current'] {
            display: none;
          }

          .alf_btn {
            display: none;
          }

          .labelForInput {
            margin: auto;
            max-width: 50%;
          }
        }

        .containerInpDefault {
          height: 100%;
          overflow: scroll;
          padding-bottom: 35px;

          -ms-overflow-style: none; // IE 10+
          overflow: -moz-scrollbars-none; // Firefox
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }
          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        .containerModifUnit {
          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.404);
          border-radius: 15px;

          .labelForInput {
            border: none;
            border-bottom: 1px solid $power_color;
            border-radius: 0;
          }

          .alf_btn {
            color: white;
          }
        }
      }
    }
  }
}
