/** BREADCRUMB*/
.pf_contain_breadcrum {
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding: 0px 30%;
}

.pf_contain_point {
       display: flex;
       justify-content: center;
       align-items: center;
}

.pf_point {
       border-radius: 300%;
       width: 10px;
       height: 10px;
       background-color: $dark_color_soft;
}

.pf_breadcrumb_active .pf_point {
       background-color: $power_color;
       box-shadow: 0 0 10px $power_color;
}
